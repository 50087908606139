@import (reference) "../../../styles/variables";
@import (reference) "../../../styles/typography";

.ImageWrapper {
  border-radius: @border-radius-default;
  border: 1px solid @color-gray-1000;
  display: flex;
  flex-direction: column;
  background-color: @color-gray-1000;
  overflow: hidden;
  .TopBar {
    height: 1.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0;
    padding: 0 0.75rem;
    border-radius: @border-radius-default @border-radius-default 0 0;
    background-color: @color-gray-1000;
    > span {
      &:first-child {
        background-color: #ff605c;
        margin-right: 0.375rem;
        height: 0.5625rem;
        width: 0.5625rem;
        border-radius: 50%;
        opacity: 0.75;
      }
      &:nth-child(2) {
        background-color: #ffbd44;
        height: 0.5625rem;
        width: 0.5625rem;
        border-radius: 50%;
        margin-right: 0.375rem;
        opacity: 0.75;
      }
      &:nth-child(3) {
        background-color: #00ca4e;
        height: 0.5625rem;
        width: 0.5625rem;
        border-radius: 50%;
        margin-right: 0.75rem;
        opacity: 0.75;
      }

      &:nth-child(4) {
        position: relative;
        margin-top: 0.25rem;
        background-color: @color-gray-1100;
        height: calc(100% - 0.25rem);
        margin-top: 0.25rem;
        border-radius: 0;
        width: @border-radius-default;

        &::after {
          position: absolute;
          bottom: 0;
          right: 0;
          content: "";
          top: 0;
          left: 0;
          background-color: @color-gray-1000;
          border-radius: 0 0 @border-radius-default 0;
        }
      }
      &:nth-child(5) {
        width: 11.5rem;
        background-color: @color-gray-1100;
        height: calc(100% - 0.25rem);
        margin-top: 0.25rem;
        border-radius: @border-radius-default @border-radius-default 0 0;
      }

      &:nth-child(6) {
        position: relative;
        margin-top: 0.25rem;
        background-color: @color-gray-1100;
        height: calc(100% - 0.25rem);
        margin-top: 0.25rem;
        border-radius: 0;
        width: @border-radius-default;

        &::after {
          position: absolute;
          bottom: 0;
          right: 0;
          content: "";
          top: 0;
          left: 0;
          background-color: @color-gray-1000;
          border-radius: 0 0 0 @border-radius-default;
        }
      }
    }
  }

  .BrowserBar {
    height: 1.8275rem;
    width: 100%;
    background-color: @color-gray-1100;
    border-bottom: 1px solid @color-gray-1000;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 0.5625rem;
    gap: 0.375rem;
    border-radius: @border-radius-default @border-radius-default 0 0;
    > span {
      height: 0.875rem;
      width: 0.875rem;
      min-width: 0.875rem;
      border-radius: 0.625rem;
      background-color: @color-gray-1000;

      &:nth-child(4) {
        width: 100%;
        height: 0.875rem;
        border-radius: 0.875rem;
      }
    }
  }

  .Image {
    aspect-ratio: 5/3;
    overflow: hidden;
    width: 100%;

    @media screen and (max-width: @breakpoint-tablet) {
      aspect-ratio: 4/3;
    }

    img {
      width: 100%;
    }
  }
}
