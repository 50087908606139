@import (reference) "../..//styles/variables";

.Form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: @breakpoint-mobile-small;
  text-align: center;

  .SubmitButton {
    margin-top: 0.25rem;
    width: 100%;
  }
}
