@import (reference) "../../styles/variables";
@import (reference) "../../styles/typography";

.Footer {
  display: flex;
  height: 2rem;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  min-height: @header-height;
  background-color: @color-black-600;
  width: 100%;
  max-width: 100vw;

  @media screen and (max-width: @breakpoint-mobile) {
    height: auto;
    padding: 1rem 1.5rem;
  }

  .Content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    width: 100%;
    padding: 0;
    @media screen and (max-width: @breakpoint-mobile) {
      flex-direction: column;
      align-items: flex-start;
      gap: 0.75rem;
      padding: 1rem 0;
    }
  }

  .Copyright {
    .type-body-small();
    flex: 0 0 auto;
    color: @color-gray-1000;
  }

  .Links {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    .type-body-small();
    color: @color-gray-1000;

    .Link {
      .link();
      .type-body-small();
      color: @color-gray-1000;
    }

    @media screen and (max-width: @breakpoint-mobile) {
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 0.75rem;
      row-gap: 0.5rem;
    }
  }

  .Social {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    flex: 0 0 auto;

    @media screen and (max-width: @breakpoint-mobile) {
      justify-content: flex-start;
      gap: 0.75rem;
      row-gap: 0.5rem;
    }
  }
}
