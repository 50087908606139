@import (reference) "../styles/variables";
@import (reference) "../styles/typography";

.Container {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  padding-top: @header-height;
}

.Main {
  display: flex;
  flex: 0;
  width: 100vw;
  max-width: 100vw;
  justify-content: center;
  align-items: flex-start;
  gap: @header-height;
  padding: @header-height 1.5rem;

  .Image {
    background-color: @color-gray-1000;
    border-radius: @border-radius-large;
    width: 19rem;
    overflow: hidden;
    height: 19rem;
    flex: 0 0 auto;
    z-index: 1;
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    .Icon {
      font-size: 12rem;
      color: @color-gray-800;
    }
  }
  .Content {
    display: flex;
    flex-direction: column;
    width: fit-content;
    gap: 1rem;
    max-width: calc(100vw - (4 * @header-height));
    z-index: 1;

    @media screen and (max-width: @breakpoint-tablet) {
      max-width: 100vw;
    }
  }

  .Header {
    .type-display();
    margin-top: 2rem;

    @media screen and (max-width: @breakpoint-tablet) {
      margin-top: 0;
    }
  }

  .Description {
    .type-subheading();
    max-width: @breakpoint-mobile-small;

    @media screen and (max-width: @breakpoint-tablet) {
      max-width: 100%;
    }
  }

  .Button {
    margin: 1rem 0;
  }

  @media screen and (max-width: @breakpoint-tablet) {
    flex-direction: column;
    align-items: flex-start;
    padding: (@header-height / 2) 1.5rem;
    gap: (@header-height / 2);
  }
}
