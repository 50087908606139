@import (inline) "./styles/normalize.css";

@import (reference) "./styles/variables";
@import (reference) "./styles/typography";

::placeholder {
  color: @color-gray-600;
  opacity: 1;
  transition: @transition-all;
}

html {
  box-sizing: border-box;
  font-size: @font-size-default;
  background-color: @color-white;
  scroll-behavior: auto;
  scroll-snap-type: y mandatory;
  scroll-padding-top: @header-height;
  height: 100vh;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

body {
  height: 100%;
}

p > a {
  .link();
}

a:focus,
button:focus {
  outline: 1.5px solid @color-red-400;
  outline-offset: 0.125rem;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

body {
  margin: 0;
  font-size: 1rem;
  color: @color-gray-400;
  font-family: @font-family-sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

b,
strong {
  font-weight: 600;
}
