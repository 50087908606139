@import (reference) "../../../styles/variables";
@import (reference) "../../../styles/typography";

.Container {
  display: flex;
  flex: 0;
  width: 100vw;
  max-width: 100vw;
  justify-content: center;
  align-items: flex-start;
  padding: @header-height 1.5rem;
  gap: @header-height;
  background-color: @color-white;
  position: relative;

  &::before {
    z-index: 1;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      @color-white 0%,
      @color-white 25%,
      rgba(@color-white, 0) 90%
    );
    z-index: 1;
  }

  .MobilePlaceholder {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    z-index: 0;
    width: 100%;
    overflow: hidden;
    background: linear-gradient(
      to bottom,
      @color-white 0%,
      @color-white 75%,
      rgba(@color-black, 0.05) 100%
    );
  }

  .VideoContainer {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 15%;
    z-index: 0;
    width: 100%;
    overflow: hidden;

    .Video {
      object-fit: cover;
      width: 100%;
      height: 100%;
      filter: blur(12px) contrast(0.75) opacity(0.125);
    }

    @media screen and (max-width: @breakpoint-mobile) {
      display: none;
    }
  }

  .Image {
    border-radius: @border-radius-large;
    width: 19rem;
    overflow: hidden;
    height: 19rem;
    flex: 0 0 auto;
    z-index: 1;
    aspect-ratio: 1;
    box-shadow: @box-shadow-default;

    img {
      width: 105%;
      height: 105%;
      object-fit: cover;
    }
  }

  .Content {
    display: flex;
    flex-direction: column;
    width: fit-content;
    gap: 1rem;
    max-width: calc(100vw - (4 * @header-height));
    z-index: 1;

    @media screen and (max-width: @breakpoint-tablet) {
      max-width: 100vw;
    }
  }

  .Header {
    .type-display();
    margin-top: 2rem;
    z-index: 1;

    @media screen and (max-width: @breakpoint-tablet) {
      margin-top: 0;
    }
  }

  .Subheader {
    .type-subheading();
    max-width: @breakpoint-mobile-small;
    z-index: 1;

    @media screen and (max-width: @breakpoint-tablet) {
      max-width: 100%;
    }
  }

  .Buttons {
    display: flex;
    gap: 0.75rem;
    margin: 1rem 0;
    z-index: 1;
  }

  @media screen and (max-width: @breakpoint-tablet) {
    flex-direction: column;
    align-items: flex-start;
    padding: (@header-height / 2) 1.5rem;
    gap: (@header-height / 2);
  }
}
