@import (reference) "../../styles/variables";
@import (reference) "../../styles/typography";

.Header {
  background-color: @color-white;
  padding: 0;
  width: 100%;
  max-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 999;
  min-height: @header-height;

  .Content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    transition: background-color 0.5s cubic-bezier(0.19, 1, 0.22, 1);

    &.Show {
      background-color: @color-black-700;

      .LogoLink {
        .Logo {
          color: @color-gray-500;
        }

        &:hover {
          .Logo {
            color: @color-gray-300;
          }
        }

        &:active {
          .Logo {
            color: @color-gray-200;
          }
        }
      }
    }
  }

  .Title {
    flex: 1 0 auto;
    position: relative;
  }

  .LogoLink {
    display: grid;
    width: fit-content;
    height: fit-content;
    text-decoration: none;
    margin-left: 1.5rem;
    outline: none;

    &.Hover {
      &:hover {
        .Logo {
          color: @color-red-100;

          &:first-child {
            transform: translateY(0.0875rem);
          }

          &:last-child {
            transform: translateY(-0.0875rem);
          }
        }
      }
    }

    &:active {
      .Logo {
        transition-duration: 0.1s;
        color: @color-red-100;

        &:first-child {
          transform: translateY(0);
        }

        &:last-child {
          transform: translateY(0);
        }
      }
    }

    &:focus {
      outline: none !important;

      .Logo {
        color: @color-gray-200;
      }
    }
  }

  .Logo {
    grid-area: 1/1/-1/-1;
    .type-heading();
    transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    color: @color-red-200;

    &:first-child {
      clip-path: polygon(0 50%, 100% 50%, 100% 100%, 0 100%);
    }

    &:last-child {
      clip-path: polygon(0 0, 100% 0, 100% 50%, 0 50%);
    }
  }

  .Nav {
    flex: 1 1 auto;
    transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1),
      opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    transform: translateY(-100%);
    transform-origin: top;
    padding: calc(@header-height + 1rem) 0 0;
    overflow: hidden;
    display: flex;
    height: 100vh;
    align-items: flex-start;
    justify-content: flex-start;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: @color-black-700;
    z-index: -1;
    opacity: 0;

    &.NavShow {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .MenuButton {
    justify-self: flex-end;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 1 auto;
  }

  .ProjectsLink {
    margin-right: 1.5rem;

    @media screen and (max-width: @breakpoint-mobile) {
      display: none;
    }
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }

  50% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}