@import (reference) "./variables";

.type-button {
  text-decoration: none;
  font-weight: 500;
  letter-spacing: @font-family-sans-serif-letter-spacing;
  font-family: @font-family-sans-serif;
}

.type-logo {
  font-family: @font-family-display;
  font-size: 2rem;
  line-height: 1;
  color: @color-gray-200;
}

.type-display {
  font-size: 4rem;
  line-height: 1;
  font-weight: 400;
  font-family: @font-family-display;
  color: @color-gray-200;
}

.type-heading {
  font-size: 2.25rem;
  line-height: 1.2;
  font-weight: 400;
  font-family: @font-family-display;
  color: @color-gray-200;
}

.type-heading-2 {
  font-size: 1.5rem;
  line-height: 1.2;
  font-weight: 400;
  font-family: @font-family-display;
  color: @color-gray-200;
}

.type-subheading {
  font-size: 1.35rem;
  line-height: 1.35;
  font-weight: 500;
  letter-spacing: @font-family-sans-serif-letter-spacing;
  color: @color-gray-400;
  font-family: @font-family-sans-serif;
}

.type-subheading-2 {
  font-size: 1.125rem;
  line-height: 1.35;
  font-weight: 500;
  letter-spacing: @font-family-sans-serif-letter-spacing;
  color: @color-gray-400;
  font-family: @font-family-sans-serif;
}

.type-1-content {
  font-size: 1.375rem;
  line-height: 1.5rem;
  font-weight: 600;
  font-family: @font-family-sans-serif;
  letter-spacing: 0.1875rem;
  text-transform: uppercase;
}

.type-2 {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-family: @font-family-sans-serif;
  text-transform: uppercase;
  color: @color-gray-1000;
  font-weight: 600;
  letter-spacing: 0.1875rem;
}

.type-2-content {
  font-size: 1.375rem;
  line-height: 1.5rem;
  font-family: @font-family-sans-serif;
  font-weight: 600;
}

.type-3 {
  font-family: @font-family-sans-serif;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.625rem;
}

.type-3-content {
  font-size: 1.0625rem;
  line-height: 1.625rem;
  font-weight: 600;
  font-family: @font-family-sans-serif;
}

.type-4 {
  font-family: @font-family-sans-serif;
  font-size: 1.25rem;
  line-height: 1.625rem;
  font-weight: 600;
}

.type-body {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  font-family: @font-family-sans-serif;
}

.type-body-small {
  font-size: 0.875rem;
  line-height: 1rem;
  font-weight: 500;
  font-family: @font-family-sans-serif;
}

.type-body-bold {
  .type-body();
  font-weight: 600;
}

.link {
  text-decoration: none;
  border-bottom: 1px solid transparent;
  margin-bottom: -1px;
  &:hover {
    border-bottom: 1px solid @color-red-200;
    color: @color-red-200;
  }
  &:focus {
    outline: none;
    color: @color-red-100;
    border-bottom: 1px solid @color-red-100;
  }
}

.type-link {
  transition: @transition-all;
  text-decoration: none;
  &:hover {
    color: @color-red-600;
  }
  &:focus {
    outline: none;
    color: @color-red-600;
  }
}

.type-body-link-black {
  .link();
}
