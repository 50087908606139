@import (reference) "../styles/variables";
@import (reference) "../styles/typography";

.Container {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  padding-top: @header-height;
}

.Main {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: @header-height 1.5rem;
  width: 100%;
  gap: @header-height;
  background-color: @color-gray-100;
  @media screen and (max-width: @breakpoint-tablet) {
    flex-direction: column;
    align-items: flex-start;
    padding: (@header-height / 2) 1.5rem calc((@header-height / 2) + 1rem);
    gap: (@header-height / 2);
  }
}

.Image {
  border-radius: @border-radius-large;
  width: 19rem;
  overflow: hidden;
  height: 19rem;
  flex: 0 0 auto;
  border: 1px solid @color-gray-1000;

  img {
    width: 105%;
    height: 105%;
    aspect-ratio: 1;
    object-fit: cover;
    object-position: top left;
  }
}

.Content {
  display: flex;
  flex-direction: column;
  width: fit-content;
  gap: 1rem;
}

.Header {
  .type-display();
  margin-top: 2rem;
  max-width: @breakpoint-mobile-small;
  color: @color-white;

  @media screen and (max-width: @breakpoint-tablet) {
    margin-top: 0;
  }
}

.Description {
  .type-subheading();
  max-width: @breakpoint-mobile-small;
  color: @color-gray-1100;

  @media screen and (max-width: @breakpoint-tablet) {
    max-width: 100%;
  }
}

.Button {
  margin: 1rem 0;
}

.Project {
  display: flex;
  flex-direction: column;
}
