@import (reference) "../../../styles/variables";
@import (reference) "../../../styles/typography";

.Section {
  display: flex;
  flex-direction: column;
  gap: 0;
  padding: 1.5rem;
  background-color: @color-white;
  border-radius: @border-radius-large;
}
