@import (reference) "../styles/variables";
@import (reference) "../styles/typography";

.Container {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  flex-direction: column;
  padding-top: @header-height;

  .Main {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: @header-height 1.5rem;
    width: 100%;
    max-width: @breakpoint-tablet;
    gap: @header-height;
    background-color: @color-white;
    @media screen and (max-width: @breakpoint-tablet) {
      flex-direction: column;
      align-items: flex-start;
      padding: (@header-height / 2) 1.5rem calc((@header-height / 2) + 1rem);
      gap: (@header-height / 2);
    }
  }
}
