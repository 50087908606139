@import (reference) "../../../styles/variables";
@import (reference) "../../../styles/typography";

.Chip {
  .type-button();
  color: @color-white;
  background-color: @color-red-400;
  height: 2.75rem;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  padding: 0 1rem;
  border-radius: @border-radius-default;
  outline: none;
  border: 2px solid transparent;
  transition: all 0.25s cubic-bezier(0.19, 1, 0.22, 1);
  position: relative;
  overflow: hidden;
  z-index: 0;
  > span {
    z-index: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
  &.Primary {
    background-color: @color-red-300;
    color: @color-white;
  }

  &.Tertiary {
    background: @color-gray-1000;
    color: @color-gray-400;
  }

  &.WhiteInverted {
    background-color: transparent;
    color: @color-white;
    border: 2px solid @color-white;
  }

  &.Lg,
  &.Large {
    height: 3.5rem;
    padding: 0 1.25em;
    font-size: 1.25rem;
    box-shadow: @box-shadow-default;
  }

  &.Sm,
  &.Small {
    height: 1.8125rem;
    padding: 0 0.5rem;
    .type-body-small();
    border-radius: @border-radius-small;
  }

  &.Xsmall,
  &.Xsm,
  &.Xs {
    height: 1.75rem;
    padding: 0 0.5rem;
    .type-body-small();
    border-radius: @border-radius-small;
  }
}
