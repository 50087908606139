@import (reference) "../../../styles/variables";
@import (reference) "../../../styles/typography";

.Container {
  background-color: @color-gray-100;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100vw;

  .Main {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: @header-height;
    width: 100%;
    max-width: @breakpoint-desktop;
    gap: (@header-height / 2);
    @media screen and (max-width: @breakpoint-tablet) {
      padding: (@header-height / 2) 1.5rem;
      gap: (@header-height / 4);
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .Icon {
    color: @color-black-800;
    svg {
      width: 12rem;
      height: 12rem;
    }

    @media screen and (max-width: @breakpoint-tablet) {
      margin-left: -0.875rem;
      svg {
        width: 8rem;
        height: 8rem;
      }
    }
  }

  .Content {
    display: flex;
    flex-direction: column;
    width: fit-content;
    gap: 1rem;
  }

  .Buttons {
    display: flex;
    gap: 0.75rem;
    margin: 1rem 0 0;
  }

  .Header {
    .type-heading();
    color: @color-white;
  }

  .Description {
    .type-body();
    max-width: @breakpoint-mobile;
    color: @color-gray-1100;

    @media screen and (max-width: @breakpoint-tablet) {
      max-width: 100%;
    }
  }
}
