@import (reference) "../../../styles/typography";

.Container {
  display: flex;
  flex-direction: column;
  &:not(:last-of-type) {
    margin-bottom: 1.75rem;
    padding-bottom: 1.75rem;
    border-bottom: 1px solid @color-gray-1000;
  }

  .Header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.75rem;

    .Title {
      display: flex;
      flex-direction: column;
    }

    .Name {
      > a {
        .type-subheading();
        color: @color-red-300;
        .link();
      }
    }

    .Image {
      width: 3rem;
      height: 3rem;
      padding: 0.5rem;
      border-radius: @border-radius-small;
      background-color: @color-gray-1000;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .Types {
      display: flex;
      gap: 0.5rem;
      .type-body-small();
    }
  }

  .Content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .Item {
      display: flex;
      flex-direction: column;

      .ItemHeader {
        display: flex;
        align-items: baseline;
        gap: 0.5rem;
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;

        .ItemTitle {
          .type-subheading-2();
          font-weight: 600;
          color: @color-gray-200;
        }

        .ItemDate {
          .type-body-small();
        }

        @media screen and (max-width: @breakpoint-mobile) {
          flex-direction: column;
          align-items: flex-start;
          gap: 0.25rem;

          .ItemDivider {
            display: none;
          }
        }
      }

      .Body {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin-top: 0.5rem;

        .Text {
          .type-body();
        }
      }

      .Stack {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
        margin-top: 1rem;
      }

      .StackItem {
        .type-body-small();
        line-height: 1.5;
        padding: 0.25rem 0.5rem;
        border-radius: @border-radius-small;
        background-color: @color-gray-1000;
      }
    }
  }
}
