@import (reference) "../../../styles/variables";
@import (reference) "../../../styles/typography";

.Container {
  background-color: rgba(@color-red-1300, 0.45);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100vw;

  .Main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: @header-height;
    width: 100%;
    max-width: @breakpoint-desktop;
    gap: (@header-height / 2);
    @media screen and (max-width: @breakpoint-tablet) {
      padding: (@header-height / 2) 1.5rem;
      gap: (@header-height / 4);
    }
  }

  .Content {
    display: flex;
    flex-direction: column;
    width: fit-content;
    gap: 1rem;
  }

  .Areas {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: auto;
    gap: 1.5rem;
    width: 100%;

    @media screen and (max-width: @breakpoint-tablet) {
      grid-template-columns: repeat(2, 1fr);
      row-gap: 0.75rem;
    }

    @media screen and (max-width: @breakpoint-mobile) {
      grid-template-columns: 1fr;
    }
  }

  .Area {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    padding: 1.5rem;
    background-color: rgba(@color-red-1300, 0.75);
    border-radius: @border-radius-large;

    .AreaName {
      .type-heading-2();
    }

    .AreaDescription {
      .type-body-small();
      line-height: 1.5;
    }
  }

  .Header {
    .type-heading();
  }

  .Description {
    .type-body();
    max-width: @breakpoint-mobile;

    @media screen and (max-width: @breakpoint-tablet) {
      max-width: 100%;
    }
  }
}
