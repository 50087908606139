@import (reference) "../styles/variables";
@import (reference) "../styles/typography";

.Container {
  background-color: @color-gray-1000;
  align-items: center;
  min-height: 100vh;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  padding-top: @header-height;

  .Main {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: (@header-height / 2) 1.5rem;
    align-items: center;
    width: 100%;
    .Icon > svg {
      width: 4rem;
      height: 4rem;
      color: @color-gray-900;
    }

    h1 {
      .type-heading();
      color: @color-gray-400;
      margin-bottom: 0.5rem;
    }

    p {
      .type-body();
      margin-top: 1.5rem;
    }
  }
}
