@import (reference) "../styles/variables";

.Container {
  align-items: center;
  background-color: @color-gray-1100;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  padding-top: @header-height;
}
